.action-button {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #1849f4;
  border: 1px solid transparent;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%), 0 0 8px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
}
.action-button-sm {
  height: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 8px;
  background-color: #1849f4;
  border: 1px solid transparent;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%), 0 0 8px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
}
.action-button:disabled, .action-button-sm:disabled {
  background-color: grey;
}
.action-button.selected {
  color: #1849f4;
  background-color: white;
}
a:has(.action-button) {
  text-decoration: none;
}
