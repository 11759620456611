.login{
    display: flex;
    width: 100%;
    background-color: #EBEDEE;
    height: 100vh;
}
.logo{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-img-bg{
    background: white;
    padding: 24px;
    width: 368px;
    height: 368px;
    display: flex;
    border-radius: 38px;
    border: 15px solid #C4C4C4;
}
.logo-img{
    border-radius: 38px;
    width: 100%;
    object-fit: contain;
    /* position: absolute; */
}
.login-heading{
    font-weight: 700;
    margin-top: 30%;
    color: #3A3A3A;
    text-transform: uppercase;
}
.input-fields{
    padding: 30px 0;
    margin-bottom: 10px;
}
.login-placeholders{
    margin-top: 10px;
    position: relative;
}
.label{
    font-weight: 500;
    font-size: 16px;
    color: #3A3A3A;
}
.user-input{
    position: absolute;
    width: 100%;
    height: 48px;
    border: none;
    padding-left: 60px;
    font-size: 15px;
    background: #FFFFFF;
    border-radius: 8px;
    /* margin-left: 12px; */
}
.user-input:focus{
    outline: none;
}
.input:focus{
    outline: none;
}
.ellipse-1{
    position: absolute;
    width: 54px;
    height: 49px;
}
.user-icon{
    position: absolute;
    width: 16px;
    height: 21px;
    margin: 13px;
}
.pass-input{
    position: absolute;
    width: 100%;
    height: 48px;
    border: none;
    padding-left: 60px;
    font-size: 15px;
    background: #FFFFFF;
    border-radius: 8px;
    /* margin-left: 10px; */
}
.pass-input:focus{
    outline: none;
}
.ellipse-2{
    position: absolute;
    width: 54px;
    height: 49px;
}
.lock-icon{
    position: absolute;
    width: 16px;
    height: 21px;
    margin: 13px;
}
.signin-links{
    margin-top: 20px;
    margin-left: 10px;
    /* padding: 0px 0px; */
}
.signin-forgot{
    display: flex;
    justify-content: end;
}
.signin-forgot-content{
    color: #1849F4;
    font-weight: 500;
    margin-bottom: 10px;
    cursor: pointer;
}
.signin-btn-content{
    width: 100%;
    padding: 13px;
    border-radius: 8px;
    background-color: #1849F4;
    border: none;
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signin-roles{
    margin-top: 20px;
}
.signin-role{
    margin-top: 10px;
    font-weight: 500;
    margin-left: 5px;
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .login{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%}
    .logo-img{
        width: 200px;
        margin-top: 30px;
    }
    .logo-img-bg{
        width: 220px;
        height: 220px;
        margin-top: 30px;
    }
    .login-heading{margin-top: 10%;}
}
@media only screen and (max-width: 400px) {
    .login{
        height: 100vh;
    }
    .logo-img{
        width: 150px;
        margin-top: 30px;
    }
    .logo-img-bg{
        width: 170px;
        height: 170px;
        margin-top: 30px;
    }
    .login-heading{
        font-size: 25px;
    }
}
@media only screen and (max-width: 440px) {
    .login-heading{font-size: 20px;}
}

.error-box{
    padding: 20px 30px 0 30px;
}