.owner{
    display: flex;
}
.owner_part{
    width: 84%;
    background-color: #EEECEC;
    height: 100vh;
}
.owner_head{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-top: 20px;
}
.owner_head_btn{
    height: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    margin: 10px;
    border-radius: 8px;
    background-color: #1849F4;
    border: 1px solid transparent;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%), 0 0 8px 0 rgb(0 0 0 / 10%);
    cursor: pointer;
}
.owner_head_link{
    text-decoration: none;
}
.owner_head_heading{
    font-size: 25px;
    color: #3a3a3a;
    font-weight: 500;
    margin: 10px;
}
.owner_container{
    padding: 20px;
    margin: 20px;
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 7px;
}
.owners-table{
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    width: 100%;
    border: 1px solid #E5E7EB;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.owners-table thead tr {
    background-color: #F2F5F7;
    color: #033E65;
    text-align: left;
    font-size: 14px;
    text-transform: uppercase;
}
.owners-table th,
.owners-table td {
    padding: 15px 17px;
    width: 1%;
}
.owners-table td{
    font-size: 14px;
    color: #6B7280;
    font-weight: 400;
}
.owners_table_actions{
    text-decoration: none;
    background: rgba(0,0,0,.06);
    margin-left: 6px;
    padding: 5px;
    border-radius: 50px;
    display: flex;
    flex-wrap: nowrap;
}
.owners_action_edit{
    color: #1849f4;
    font-size: 18px;
}
.owners_action_delete{
    color: #d91e00;
    font-size: 20px;
}
.owners_action_pin{
    color: #366c2d;
    font-size: 20px;
}


/* ======  Add Owner  ====== */
.add{
    display: flex;
}
.add_part{
    width: 84%;
    background-color: #EEECEC;
    height: 100vh;
}
.add_head{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-top: 20px;
}
.add_head_btn{
    height: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    margin: 10px;
    border-radius: 8px;
    background-color: #1849F4;
    border: 1px solid transparent;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%), 0 0 8px 0 rgb(0 0 0 / 10%);
    cursor: pointer;
}
.add_head_btn:hover{
    background: #fff;
    border: 1px solid #1849F4;
    color: #1849F4;
}
.add_head_link{
    text-decoration: none;
}
.add_head_heading{
    font-size: 25px;
    color: #3a3a3a;
    font-weight: 500;
    margin: 10px;
}
.add_back_icon_li{
    text-decoration: none;
    color: #3a3a3a;
    margin-right: 5px;
}
.add_back{
    margin-top: 15px;
    margin-left: 10px;
}
.add_back_icon{
    font-size: 20px;
}
.owner_creation_container{
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 8px;
    margin: 0 auto;
    margin: 20px;
    padding: 20px;
}
.owner_creation_fields{
    margin: 10px;
}
.owner_creation_label{
    color: #3a3a3a;
    display: block;
    font-size: 15px;
    font-weight: 500;
}
.owner_creation_input{
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 3px;
    border: 1px solid #bbb;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    transition: .2s cubic-bezier(.4,0,.2,1);
}
.owner_creation_phone_input{
    width: 96%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 1%;
    border-radius: 3px;
    border: 1px solid #bbb;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    transition: .2s cubic-bezier(.4,0,.2,1);
}
.owner_creation_phone_input:focus{
    outline: none;
    border: 1px solid green;
}
.owner_creation_input:focus{
    outline: none;
    border: 1px solid green;
}



/* =========  EDIT OWNER =========== */
.owner_edit_container{
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 8px;
    margin: 0 auto;
    margin: 20px;
    padding: 20px;
}
.owner_send_otp{
    display: flex;
    justify-content: space-between;
}
.send_otp_btn{
    padding: 8px;
    width: 10%;
    background-color: #1849F4;
    border: 1px solid transparent;
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
}
.owner_creation_otp_input{
    width: 40%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 3px;
    border: 1px solid #bbb;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    margin-left: 60%;
    transition: .2s cubic-bezier(.4,0,.2,1);
}
.owner_creation_otp_label{
    color: #3a3a3a;
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin-left: 60%;
}
.owner_creation_otp_input:focus{
    outline: none;
    border: 1px solid green;
}
.owner_otp{
    display: flex;
    justify-content: end;
    margin-right: 10px;
}
.cursor-pointer{
    cursor: pointer;
}