.custom-select {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 3px;
    border: 1px solid #bbb;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    transition: .2s cubic-bezier(.4,0,.2,1);
}
.custom-select:focus {
    outline: none;
    border: 1px solid green;
}

.custom-input{
    height: 40.5px;
}