@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@200;400;500;600;700;800;900&display=swap');

.dashboard-table{
    font-family: 'Inter', sans-serif !important;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 0.9em;
    width: 100%;
    border: 1px solid #E5E7EB;
}
.dashboard-table thead tr {
    background-color: #F2F5F7;
    color: #033E65;
    text-align: left;
    font-size: 14px;
    text-transform: uppercase;
}
.dashboard-table th,
.dashboard-table td {
    font-family: 'Inter', sans-serif !important;
    padding: 15px 17px;
    width: 1%;
}
.dashboard-table td{
    font-size: 14px;
    color: #6B7280;
    font-weight: 400;
}
.dashboard-table th {
    font-weight: normal;
}