.side-nav {
    width: 250px;
    /* position: fixed; */
    top: 0;
    left: 0;
    min-height: 100vh;
    /* height: 100vh; */
    background-color: white;
    z-index: 999;
    transition: width 0.5s;
}
.side-nav ul {
    list-style: none;
    margin: 60px auto;
    padding-left: 0;
}
.side-nav ul a {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    color: #00000099;
}
.side-nav ul a.active li {
    background-color: #1849F4;
    border-radius: 10px;
    color: white;
}
.side-nav ul a:hover li {
    background-color: #1849F4;
    border-radius: 10px;
    color: white;
}
.side-nav ul a li {
    display: flex;
    align-items: center !important;
    margin: 10px 10px;
    padding: 10px 20px;
    height: 45px;
}
.side-nav ul li span {
    margin-left: 8px;
    display: inline;
}
.side-nav-collapse {
    width: 55px;
}
.side-nav-collapse ul a li span {
    display: none;
}
.side-nav-collapse ul {
    margin-left: 0;
    margin-right: 0;
    margin: 115px auto;
}
.side-nav-collapse ul a li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px 5px;
}

/* Logo and Logo Container styles */
.logo-container {
    background: white;
    border: 2px solid #C4C4C4;
    border-radius: 4px;
    margin: auto;
    text-align: center;
    width: fit-content;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.side-nav .logo-container img {
    width: 120px;
    transition: width 0.5s;
}
.side-nav-collapse .logo-container img {
    width: 40px;
}
.side-nav .logo-container h5 {
    text-transform: uppercase;
    margin: 0;
}
.side-nav-collapse .logo-container h5 {
    display: none;
}