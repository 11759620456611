.tabs{
    padding: 30px;
    margin: 30px;
}
.tabs-name{
    color: #111827;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab-list{
    display: flex;
    justify-content: space-between;
    border: 1px solid #DADFDD;
    background-color: #F2F5F7;
    border-radius: 7px;
    padding: 17px;
}
.tab-lists{
    display: flex;
    justify-content: end;
    width: 80%;
}
.tab{
    list-style: none;
    padding: 13px;
    background-color: #1849F4;
    margin-right: 20px;
    width: 19%;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    display: flex;
    cursor: pointer;
}
.tab-lists .active:hover{
    background-color: red !important;
}

.tab-icon{
    margin-right: 15px;
    font-size: 23px;
    font-weight: bold;
}