@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700");

.Certificate h2{
    font-size: 1.5em;
}

.Certificate .header-id {
    font-weight: 800;
}

.Certificate .header-title{
    color: rgba(83, 158, 227, 1);
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Certificate h1{
    font-size: 1.8em;
}

.owner-name{
    color: blue;
}

.Certificate .container {
    max-width: 800px;
    margin: 0 auto;
    background: transparent;
    border-radius: 19px;
    margin-top: 0px;
}

.Certificate body {
    border: none;
    background: #eee;
    font-family: "Roboto", arial;
}

.Certificate tr.row.w-100.aic.fjc {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}

.Certificate tr.row.w-100.aic.fjc>th {
    width: 33.33%;
    text-align: center;
}

.Certificate .content-summary {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.Certificate .content-summary .single_row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.Certificate .content-summary .single_row b {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    margin-right: 30px;
}

.Certificate .content-summary .single_row>span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 8px 0;
    font-size: 18px;
    line-height: 18px;
}

.Certificate .content-summary .single_row span span {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.Certificate .owner-details {
    text-align: center;
    margin: 40px 0 30px 0;
    clear: both;
}

.Certificate .owner-details .owner-title {
    font-size: 18px;
    font-style: italic;
    color: #333;
    font-weight: 400;
    margin-bottom: 5px;
    display: block;
    font-weight: 500;
}

.Certificate .owner-details .owner-name {
    font-weight: 600;
    font-size: 32px;
}

.Certificate .footer_single {
    width: 33.33%;
    text-align: center;
}

.Certificate .print_footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.Certificate .footer_bottom,
.Certificate .footer_bottom * {
    color: #555;
    font-weight: 500;
}

.Certificate .footer_bottom ol {
    list-style: none;
    padding: 0;
    text-align: center;
    font-style: italic;
    font-size: 15px;
}

.Certificate .footer_single h2 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
}

.Certificate .footer_single h4 {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 5px;
    border-top: 1px solid #666;
    padding-top: 10px;
}

.Certificate .img_header_after {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;
}

.Certificate .container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}

.Certificate .content-summary {}

.Certificate .container {
    background-image: url(https://i.imgur.com/BNOk3tw.png);
    background-size: 410px;
    padding: 20px;
    border: 40px solid #ddd;
    border-image-source: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/652/hearts-border-image.png);
    border-image-slice: 40 50;
    border-image-repeat: round;
    border-image-source: url(https://i.imgur.com/cgUbp9j.png);
}

.Certificate .container_parent {
    min-height: 100vh;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-image: url("https://i.imgur.com/VNKwf7Z.png");
    background-color: #fff;
    border-radius: 19px;
    margin-top: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 320px;
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
    }
}