 .flex {
    display: flex;
}
.justify-between {
    justify-content: space-between;
}
.align-center {
    align-items: center;
}
.mx-20 {
    margin-left: 20px;
    margin-right: 20px
}
.mt-5 {
    margin-top: 2em;
}
.mt-2 {
    margin-top: 0.5em;
}
.mt-3 {
    margin-top: 1em;
}
.icons-td-link {
    text-decoration: none;
    background: rgba(0,0,0,.06);
    margin-left: 6px;
    padding: 5px;
    border-radius: 50px;
    display: flex;
    flex-wrap: nowrap;
}
.grow {
    flex-grow: 1;
}
.justify-center {
    justify-content: center;
}
.cursor-pointer {
    cursor: pointer;
}