@media  print {
    header, footer, aside, form, … {
        display: none;
    }

    @page  {
        size: portrait;
        margin: 0;
        width: 100;
        height: 120;
    }
}

.Receipt  h1 {color: blue;}

.Receipt  a:link {
    color: navy;
    text-decoration: underline;
}

.Receipt  p {
    margin-left: 5%;
    margin-right: 5%;
    padding: 1%;
}

.Receipt  p:first-line {
    font-size: 120%; font-weight: bold;
    color: black;
}

.Receipt  p.footnote {
    font-size: smaller;
}

.Receipt  blockquote {
    font-style: italic;
}

.Receipt  blockquote em {
    font-style: normal;
}

.Receipt  pre, code, tt {
    color: gray; font-family: monospace;
}

body, th, td, h5 {
    color: #000;
}

.Receipt  table {
    margin-left: auto;
    margin-right: auto;
}

.Receipt  h1 {
    font-size: 1.8em;
}

.Receipt  .owner-title {
    padding: 0;
    margin: 0;
    font-size: 18px
}

.Receipt  .owner-name {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-align: center
}

.Receipt  .header-title {
    color: rgba(83, 158, 227, 1);
    font-weight: 800;
}

.Receipt  h1:first-letter {
    font-size: 101%;
}

.Receipt .container {
    padding: 20px;
    display: block;
}

.Receipt  .content-summary {
    margin-bottom: 20px;
}

.Receipt  .table {
    margin-top: 20px;
}

.Receipt  .table table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.Receipt  .table thead th {
    font-weight: 700;
    border-top: solid 1px #d3d3d3;
    border-bottom: solid 1px #d3d3d3;
    border-left: solid 1px #d3d3d3;
    padding: 5px 10px;
    background: #F4F4F4;
}

.Receipt  .table thead th:last-child {
    border-right: solid 1px #d3d3d3;
}

.Receipt  .table tbody td {
    padding: 5px 10px;
    border-bottom: solid 1px #d3d3d3;
    border-left: solid 1px #d3d3d3;
    color: black;
    vertical-align: middle;
}

.Receipt  .table tbody td p {
    margin: 0;
}

.Receipt  .table tbody td:last-child {
    border-right: solid 1px #d3d3d3;
}

.Receipt  #summary-table {
    border-collapse: collapse;
    border: 2px solid black;
    width: 100%;
    font-size: 18px;
}

.Receipt  #summary-table > tr, #summary-table > td {
    border: 2px solid black;
}


.Receipt  .reciept_container h2{
    font-size: 1.5em;
    font-weight: bold;
    color: black;
}

.Receipt  .reciept_container h4 {
    font-size: 1em;
    font-weight: bold;
}

.Receipt  .reciept_container h3 {
    font-size: 1.17em;
    font-weight: bold;
}

/* ******************************** */


.Receipt .container {
    max-width: 800px;
    margin: 0 auto;
    background: transparent;
    border-radius: 19px;
    margin-top: 0px;
}

.Receipt  tr.row.w-100.aic.fjc {display: flex;align-items: center;justify-content: center;max-width: 100%;}

.Receipt  tr.row.w-100.aic.fjc>th {
    width: 33.33%;
    text-align: center;
}

.Receipt  .content-summary {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.Receipt  .content-summary .single_row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Receipt  .content-summary .single_row b {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    margin-right: 30px;
}

.Receipt  .content-summary .single_row>span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 8px 0;
    font-size: 18px;
}

.Receipt  .content-summary .single_row span span {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.Receipt  .owner-details {
    text-align: center;
    margin: 40px 0 30px 0;
    clear: both;
}

.Receipt  .owner-details .owner-title {font-size: 18px;font-style: italic;color: #333;font-weight: 400;margin-bottom: 5px;display: block;}

.Receipt  .owner-details .owner-name {
    font-weight: 600;
    font-size: 32px;
}

.Receipt  .footer_single {
    width: 33.33%;
    text-align: center;
}

.Receipt  .print_footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.Receipt  .footer_bottom,.footer_bottom * {
    color: #555;
}

.Receipt  .footer_bottom ol {
    list-style: none;
    padding: 0;
    text-align: center;
    font-style: italic;
    font-size: 15px;
}

.Receipt  .footer_single h2 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
}

.Receipt  .footer_single h4 {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 5px;
    border-top: 1px solid #666;
    padding-top: 10px;
}

.Receipt  .img_header_after {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;
}

.Receipt .container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}

.Receipt .container {
    background-image: url(https://i.imgur.com/BNOk3tw.png);
    background-size: 410px;
    padding: 20px;
    border: 40px solid #ddd;
    border-image-source: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/652/hearts-border-image.png);
    border-image-slice: 40 50;
    border-image-repeat: round;
    border-image-source: url(https://i.imgur.com/cgUbp9j.png);
}

.Receipt  .container_parent {
    min-height: 100vh;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-image: url("https://i.imgur.com/VNKwf7Z.png");
    background-color: #fff;
    border-radius: 19px;
    margin-top: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 320px;
}

@media  print {
    * {
        -webkit-print-color-adjust: exact;
    }
}

/* ****************************** */


.Receipt  .container.reciept_container {
    padding: 0;
    background: #fff;
}

.Receipt  .content-summary {
    margin-top: 0;
    margin-bottom: 0;
}

.Receipt  .table_summary td {
    border: 1px solid #888;
    font-weight:500;
}

.Receipt  #summary-table.table_summary {
    border: none;
}

.Receipt  .table_summary td:first-child {
    width: 200px !important;color: #0769b2;
    font-weight: 500;
    text-align: right;
    padding: 7px;
}

.Receipt  .reciept_container th {
    text-align: center;
}

.Receipt  .content-summary h3 {
    margin: 3px 0;
}

.Receipt  .copy {
    text-align: right;
    color: #888;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 0.7;
    margin-top:10px;
}

.Receipt  .header_tr th {
    width: 40%;
}