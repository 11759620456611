@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@200;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif !important;
}

body {
  font-family: 'Roboto', sans-serif !important;
  margin: 0;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #EBEDEE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}


.font-13 {
  font-size: 13px;
}

.font-12 {
  font-size: 12px;
}

.underline{
  text-decoration: underline;
}

.map{
  text-align: center;
  align-self: start;
}
.map .action-button{
  padding: 8px 10px;
  
}

.carmo-hr{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: black;
  opacity: 1;
}

.map-page-table-container .dashboard-table{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.map-page-logo{
  width: 80px;
}

.map-section{
  background-image: url(./assets/map-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.records-form input, .service-form input, .owners-form input{
  border-radius: 15px !important;
  height: 48px;
}

.records-table tr td:first-child{
  font-weight: 500;
  color: black !important;
}

.action-btns .action-button{
  height: 48px;
}

.map {
  height: 100%;
}