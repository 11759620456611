.search-results{
    border: 1px solid #DADFDD;
    margin: 15px;
    border-radius: 7px;
    padding: 32px;
    background-color: #ffffff;
}
.search-results-headings{
    display: flex;
    justify-content: space-between;
}
.search-results-heading{
    font-weight: 500;
    font-size: 16px;
}
.search-results-view{
    color: #6B7E78;
}
.content-table{
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    width: 100%;
    border: 1px solid #E5E7EB;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.content-table thead tr {
    background-color: #F2F5F7;
    color: #033E65;
    text-align: left;
    font-size: 14px;
    text-transform: uppercase;
}
.content-table th,
.content-table td {
    padding: 15px 17px;
    width: 1%;
}
.content-table td{
    font-size: 14px;
    color: #6B7280;
    font-weight: 400;
}