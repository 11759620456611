@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");

@media print {

    aside,
    footer,
    form,
    .top_bar_lead,
    … {
        display: none
    }

    @page {
        size: landscape;
        margin: 0;
        width: 100;
        height: 120
    }
}

h1{
    font-size: 2em !important;
}

.Report a:link {
    color: navy;
    text-decoration: underline
}

.Report table {
    margin-left: auto;
    margin-right: auto
}

.Report body {
    border: none;
    background: #eee;
    font-family: Roboto, arial;
    min-height: 100vh
}

.Report .container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%
}

.Report .container {
    padding: 10px 20px;
}

.Report .container_parent {
    min-height: 100vh;
    height: 100%;
    max-width: 1300px;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 0;
}

.Report .w-100 {
    width: 100% !important
}

.Report .text-left {
    text-align: left !important
}

.Report .text-right {
    text-align: right !important
}

.Report .ml-0,
.Report .mx-0 {
    margin-left: 0 !important
}

.Report .mr-0,
.Report .mx-0 {
    margin-right: 0 !important
}

.Report .top_bar_lead {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
}

.Report .top_bar_lead th {
    width: 100%;
    font-size: .45em
}

.Report .table_dynamic {
    border: 1px solid #666;
    padding: 0;
    margin: 0px 0 25px 0
}

.Report .table_dynamic th {
    background: #ddd;
    padding: 5px 2px;
    text-align: center;
    border: 1px solid #666;
    border-left: 0;
    border-top: 0;
    border-bottom: 3px double #666
}

.Report .table_dynamic td {
    background: 0 0;
    padding: 4px 2px;
    text-align: center;
    border: 1px solid #666;
    border-left: 0;
    border-top: 0;
    word-break: break-word;
    white-space: normal
}

.Report .table_dynamic tr:last-child td {
    border-bottom: 0;
}

.Report .table_dynamic>tbody>tr td:last-child {
    border-right: 0;
}

.Report .table_dynamic>thead>tr th:last-child {
    border-right: 0;
}

.Report .table_dynamic>tbody>tr:nth-child(even) td {
    background: #eee;
}

.Report .top_bar_lead-right {
    font-weight: 400;
    color: #333
}

.Report .top_bar_lead-right .highlight {
    border-bottom: 2px solid #000;
    margin-bottom: 10px;
    display: inline-block;
    padding-bottom: 1px;
    color: #000
}

.Report .data_dynamic_footer {
    padding-top: 10px;
    margin-top: auto;
    text-align: right;
    color: #666;
    font-style: italic;
    border-top: 2px dashed #ddd
}

.Report .data_dynamic_top_bar_lead {
    border-bottom: 2px dashed #ddd;
    margin-bottom: 20px
}

.Report .report_title {
    margin-bottom: 10px;
    margin-top: 12px;
}

.Report .subtable {
    border: 1px solid transparent;
    padding: 0
}

.Report .report_date {
    color: #000;
    font-size: 0.85em;
}

.Report .report_time {
    color: #666;
    font-size: 0.85em;
}

.Report .table_dynamic tr td .subtable td {
    border: transparent;
    border-bottom: 1px solid transparent;
    text-align: left !important;
    word-break: break-word;
}

.Report .table_dynamic tr td .subtable td {
    font-size: 14px !important;
    line-height: 14px !important;
    font-weight: 700
}

.Report .table_dynamic tr td .subtable td:first-child {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400
}

.Report .table_dynamic tr td .subtable td:first-child:before {
    content: ''
}

.Report .table_dynamic tr td .subtable.columns2 tr td {
    padding: 6px 6px;
    border-bottom: 1px dashed #aaa;
}

.Report .top_bar_lead th:first-child {
    width: 70px;
}

.Report .top_bar_lead-title.text-left {
    font-size: 23px !important;
    font-weight: 700;
    margin-left: 10px;
}

.Report .report_date {
    white-space: nowrap;
}

.Report td[align="right"] {
    text-align: right !important;
}

.Report td[align="left"] {
    text-align: left !important;
}

.Report .bottom_total td {
    background: #5f93ba;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    padding: 7px 10px;
}

.Report .float-right {
    float: right;
    margin: 0 0 10px 0;
}

.Report .white-space {
    white-space: nowrap !important;
}

.Report .pr-5 {
    padding-right: 10px !important
}

.Report .capitalize {
    text-transform: capitalize !important;
}

.Report .table_dynamic>thead>tr>th {
    text-transform: capitalize;
}

.Report .badge {
    white-space: nowrap;
    font-size: 12px;
    border-radius: 5px;
    padding: 3px 4px;
    border: 1px solid #ccc;
    text-transform: uppercase;
    font-weight: 500;
}

.Report .badge-warning {
    background: #fffa8e;
    color: #545218;
}

.Report .badge-success {
    background: #13b943;
    color: #fff;
}

.Report .table_dynamic tr td .subtable.columns2 td:first-child,
.Report .table_dynamic tr td .subtable.columns2 td {
    white-space: normal;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: center;
}

.Report .table_dynamic tr td .subtable.columns2 {
    min-width: 250px;
    max-width: 250px;
    width: 100%;
}

.Report .subtable.columns2 td strong {
    display: inline-block;
    float: left;
}

.Report .subtable.columns2 td span {
    float: left;
    width: 110px;
    min-width: 110px;
    display: inline-block;
}

.Report [align="top"] {
    vertical-align: top !important;
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
    }
}
