.search{
    background-color: #EBEDEE;
    padding-right: 50px;
    padding-left: 50px;
}
.search-header{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    background-color: #EBEDEE;
}
.search-header-{
    display: flex;
    margin: 10px;
}
.search-logo{
    width: 85px;
    cursor: pointer;
}
.search-logo-name{
    padding-top: 30px;
    margin-left: 32px;
    color: #000000;
    font-weight: bold;
    font-size: 24px;
}
.search-name{
    margin: 23px;
    color: #000000;
    font-weight: 400;
    font-size: 18px;
}
.search-name
span{
    color: #1849F4;
}
.search-logout{
    height: 30px;
    margin: 20px;
    cursor: pointer;
}
.search-bar{
    border: 1px solid #DADFDD;
    border-radius: 7px;
    background-color: #F2F5F7;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 15px;
}
.search-bar-fields{
    padding: 15px;
}
.search-bar-field{
    padding: 17px;
    margin: 20px;
    margin-left: 40px;
    width: 20%;
    border-radius: 20px;
    border: none;
}
.search-bar-field:focus{
    outline: none;
}
.search-bar-btn{
    width: 20%;
    padding: 17px;
    margin: 20px;
    background-color: #1849F4;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 8px;
}
.search-bar-btn:hover{
    background-color: blue;
}