.custom-input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #bbb;
    background-color: #fff;
    display: inline-block;
    font-size: 15px;
    transition: .2s cubic-bezier(.4,0,.2,1);
}
.custom-input:focus {
    outline: none;
    border: 1px solid green;
}
.input-error {
    border: 1px solid red;
}
.input-error:focus {
    border: 1px solid red;
}