.dashboard {
    display: flex;
}

.dashboard_part {
    width: 84%;
    background-color: #EEECEC;
    /* height: 100vh; */
}

.dashboard_head {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-top: 20px;
}

.dash_head_btn {
    height: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    margin: 10px;
    border-radius: 8px;
    background-color: #1849F4;
    border: 1px solid transparent;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%), 0 0 8px 0 rgb(0 0 0 / 10%);
    cursor: pointer;
}

.dash_head_link {
    text-decoration: none;
}

.dash_head_heading {
    font-size: 25px;
    color: #3a3a3a;
    font-weight: 500;
    margin: 10px;
}







.prf-status {
    display: flex;
    margin-bottom: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.prf-status .report-highligter {
    background: #fff;
    border: 1px solid #e4e4e4;
    -webkit-box-shadow: 0 10px 16px -6px rgba(0, 0, 0, .03);
    box-shadow: 0 10px 16px -6px rgba(0, 0, 0, .03);
    border-radius: 8px;
    padding: 20px 15px 15px;
    margin-right: 15px;
    width: 32%;
    max-width: 400px;
    margin-bottom: 15px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    position: relative;
}

.prf-status .report-highligter:last-child {
    margin-right: 0
}

.prf-status .report-highligter .type-highlighter {
    font-weight: 400;
    font-size: 16px;
    margin-left: 0;
    line-height: 20px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #b7b7c0;
    -webkit-transition: all .2s linear;
    transition: all .2s linear
}

.prf-status .report-highligter h3 {
    font-size: 35px;
    line-height: 32px;
    margin-top: 12px;
    font-weight: 500;
    color: #11142d;
    margin-bottom: 9px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear
}

.prf-status .report-highligter h3 .unit {
    font-size: 75%
}

.prf-status .report-highligter:hover {
    border-color: #0041ff;
    -webkit-box-shadow: 0 10px 16px -5px rgba(0, 0, 0, .1);
    box-shadow: 0 10px 16px -5px rgba(0, 0, 0, .1)
}

.prf-status .report-highligter:hover .type-highlighter,
.prf-status .report-highligter:hover h3 {
    color: #0041ff
}



.btn_download_report {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ccc;
    padding: 4px 10px 4px 7px;
    border-radius: 4px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #f3f3f3;
    background: -webkit-gradient(linear, left bottom, left top, from(#e9e9e9), to(transparent));
    background: linear-gradient(0deg, #e9e9e9, transparent);
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    font-size: .9em;
    margin: 0 -3px -3px 0
}

.btn_download_report .img {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cpath fill='%23FF5722' d='M40 45H8V3h22l10 10z'/%3E%3Cpath fill='%23FBE9E7' d='M38.5 14H29V4.5z'/%3E%3Cpath fill='%23FFEBEE' d='M15.81 29.5V33H13.8v-9.953h3.391c.984 0 1.77.306 2.355.916s.878 1.403.878 2.379-.29 1.745-.868 2.311-1.381.847-2.407.847H15.81zm0-1.675h1.381c.383 0 .679-.125.889-.376s.314-.615.314-1.094c0-.497-.107-.892-.321-1.187a1.032 1.032 0 0 0-.861-.447H15.81v3.104zM21.764 33v-9.953h2.632c1.162 0 2.089.369 2.778 1.107.691.738 1.043 1.75 1.057 3.035v1.613c0 1.308-.346 2.335-1.035 3.079-.692.747-1.643 1.119-2.855 1.119h-2.577zm2.009-8.278v6.61h.602c.67 0 1.142-.177 1.415-.53.273-.353.417-.962.431-1.828v-1.729c0-.93-.13-1.578-.39-1.944-.26-.367-.702-.56-1.326-.578h-.732zm11.034 4.217h-3.124V33h-2.01v-9.953h5.51v1.675h-3.5v2.55h3.124v1.667z'/%3E%3C/svg%3E");
    width: 25px;
    height: 25px;
    background-size: cover;
    background-position: 50%
}

.btn_download_report .img,
.btn_download_report span {
    display: inline-block;
    vertical-align: middle
}

.btn_download_report:hover {
    border: #11142d 1px solid;
}

.rotate {
    width: 100px;
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}